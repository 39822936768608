<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <profile-header :header-data="profileData.header" />
    <!-- profile info  -->
    <section id="profile-info">
      <b-card>
        <b-row>
          <b-col sm="4">
            <b-card-title>مشخصات فردی</b-card-title>
            <b-list-group>
              <b-list-group-item> نام : محمد علی </b-list-group-item>
              <b-list-group-item> نام خانوادگی : قاسمی نیا</b-list-group-item>
              <b-list-group-item>کد ملی : 037xxxxxxx</b-list-group-item>
              <b-list-group-item>تاریخ تولد : 01/01/1368</b-list-group-item>
              <b-list-group-item>تلفن همراه : 0910xxxxxxx</b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col sm="4">
            <b-card-title>مشخصات وسیله نقلیه</b-card-title>
            <b-list-group>
              <b-list-group-item>نوع : سواری</b-list-group-item>
              <b-list-group-item>برند : ایران خودرو</b-list-group-item>
              <b-list-group-item>مدل : 1399</b-list-group-item>
              <b-list-group-item>میزان کارکرد : 100000</b-list-group-item>
              <b-list-group-item>پلاک : 25 ه 685</b-list-group-item>
              <b-list-group-item>رنگ : مشکی</b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import ProfileHeader from "./ProfileHeader.vue";

/* eslint-disable global-require */
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCard,
    BRow,
    BCol,
    ProfileHeader,
  },
  data() {
    return {
      profileData: {},
    };
  },
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import "@core/scss/vue/pages/page-profile.scss";
</style>
